import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UniversalAppInterceptor } from './pages/pages/auth/UniversalAppInterceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmRegistrationComponent } from './pages/pages/auth/confirm-registration/confirm-registration.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NewConmonentComponent } from './pages/apps/new-conmonent/new-conmonent.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


import { NgxSpinnerModule } from 'ngx-spinner';
import { UserCreationModule } from './pages/apps/coopérateurs/user-creation/user-creation.module';
import { ListUsersModule } from './pages/apps/coopérateurs/list-users/list-users.module';



// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  /* for development
  return new TranslateHttpLoader(
      http,
      '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
      '.json'
  ); */
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
@NgModule({
  declarations: [AppComponent,  
        ],
  exports:[],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSlideToggleModule,
    NgxSpinnerModule,

    NgxDaterangepickerMd.forRoot(),
    // Vex
    VexModule,
    CustomLayoutModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
  }),
  
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: UniversalAppInterceptor, multi: true }, ],
  bootstrap: [AppComponent]
})
export class AppModule { }
